<template>
  <div>
    <v-footer
      app
      v-if="isFixed"
      :padless="true"
    >
      <v-card
        flat
        tile
        width="100%"
        class="grey lighten-5 text-center"
      >
        <v-card-text>

        <v-container style="max-width: 1000px;">
          <v-row>
            <v-col xs="12" sm="6" class="d-flex align-center partner">
              <a href="http://zti.hu/index.php/hu/" target="_blank">
                <v-row>
                  <v-col cols="4">
                    <v-img src="/img/partners/zti.png" height="90" contain></v-img>
                  </v-col>
                  <v-col cols="8">
                    <v-img src="/img/partners/footer_zti_logotext.png" height="90" contain></v-img>
                  </v-col>
                </v-row>
              </a>
            </v-col>
            <v-col xs="12"  sm="6"  class="d-flex align-center partner">
                    <a href="https://www.polyphonyproject.com/" target="_blank">
                      <v-img src="/img/partners/polyphony.png" height="90" contain></v-img>
                    </a>
            </v-col>
          </v-row>
        </v-container>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="">
          &copy; {{ new Date().getFullYear() }} — <span v-html="$t('footer_text')"></span>
        </v-card-text>
      </v-card>        
    </v-footer>
    <v-footer
      v-else
      :padless="true"
    >
      <v-card
        flat
        tile
        width="100%"
        class="grey lighten-5 text-center"
      >
        <v-card-text>

        <v-container style="max-width: 1000px;">
          <v-row>
            <v-col xs="12" sm="6" class="d-flex align-center partner">
              <a href="http://zti.hu/index.php/hu/" target="_blank">
                <v-row>
                  <v-col cols="4">
                    <v-img src="/img/partners/zti.png" height="90" contain></v-img>
                  </v-col>
                  <v-col cols="8">
                    <v-img src="/img/partners/footer_zti_logotext.png" height="90" contain></v-img>
                  </v-col>
                </v-row>
              </a>
            </v-col>
            <v-col xs="12"  sm="6"  class="d-flex align-center partner">
                    <a href="https://www.polyphonyproject.com/" target="_blank">
                      <v-img src="/img/partners/polyphony.png" height="90" contain></v-img>
                    </a>
            </v-col>
          </v-row>
        </v-container>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="">
          &copy; {{ new Date().getFullYear() }} — <span v-html="$t('footer_text')"></span>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    isFixed () {
      if (['xs', 'sm'].includes(this.$vuetify.breakpoint.name)) return false
      return ['page', 'keyword', 'item'].includes(this.$route.name)
    }
  }
}
</script>


<style scoped>
.partner a {
  width: 100%;
}
</style>