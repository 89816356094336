export const ES_INDEX = {
  dance: {
    hu: process.env.VUE_APP_ES_INDEX_DANCES_HU,
    en: process.env.VUE_APP_ES_INDEX_DANCES_EN,
  },
  photo: {
    hu: process.env.VUE_APP_ES_INDEX_PHOTOS_HU,
    en: process.env.VUE_APP_ES_INDEX_PHOTOS_EN,
  },
  rhyme: {
    hu: process.env.VUE_APP_ES_INDEX_RHYMES_HU,
    en: process.env.VUE_APP_ES_INDEX_RHYMES_EN,
  },
  script: {
    hu: process.env.VUE_APP_ES_INDEX_SCRIPTS_HU,
    en: process.env.VUE_APP_ES_INDEX_SCRIPTS_EN,
  },
  notation: {
    hu: process.env.VUE_APP_ES_INDEX_NOTATIONS_HU,
    en: process.env.VUE_APP_ES_INDEX_NOTATIONS_EN,
  },
  film: {
    hu: process.env.VUE_APP_ES_INDEX_FILMS_HU,
    en: process.env.VUE_APP_ES_INDEX_FILMS_EN,
  },
  motif: {
    hu: process.env.VUE_APP_ES_INDEX_MOTIFS_HU,
    en: process.env.VUE_APP_ES_INDEX_MOTIFS_EN,
  },
  lexicon: {
    hu: process.env.VUE_APP_ES_INDEX_LEXICON_HU,
    en: process.env.VUE_APP_ES_INDEX_LEXICON_EN,
  },
  bibliography: {
    hu: process.env.VUE_APP_ES_INDEX_BIBLIOGRAPHIES_HU,
    en: process.env.VUE_APP_ES_INDEX_BIBLIOGRAPHIES_EN,
  },
  ebook: {
    hu: process.env.VUE_APP_ES_INDEX_EBOOKS_HU,
    en: process.env.VUE_APP_ES_INDEX_EBOOKS_EN,
  },
  historicalsource: {
    hu: process.env.VUE_APP_ES_INDEX_HISTORICALSOURCES_HU,
    en: process.env.VUE_APP_ES_INDEX_HISTORICALSOURCES_EN,
  },
  person: {
    hu: process.env.VUE_APP_ES_INDEX_PERSON_HU,
    en: process.env.VUE_APP_ES_INDEX_PERSON_EN,
  },
};
