<template>
  <v-dialog
        v-model="dialog"
        width="60%"
        v-if="item !== null"
        style='z-index:5001;'
        scrollable
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on }">

          <template v-if="inlineTextLink">
            <template v-if="text">
              <span v-on="on" class="primary--text it-is-link">{{ text }}</span>
            </template>
            <template v-else>
              <span v-on="on" class="primary--text it-is-link">{{ item.item_id }}</span>
            </template>
          </template>
          <template v-else>
            <template v-if="item.type === 300">
              <v-img
                :src="imgResized(item.media_image_file)"
                :width="width"
                v-on="on"
                v-if="item.media_image_file"
              ></v-img>
              <v-img
                :src="photocard_missing"
                :width="width"
                v-on="on"
                v-else
              ></v-img>
            </template>
            <template v-else-if="item.type === 500">
              <v-img
                :src="yt_thumbnail"
                class="yt_thumbnail"
                :width="width"
                v-on="on"
                :aspect-ratio="1.3193"
              ></v-img>
            </template>
            <div v-on="on" class="it-is-link">
              {{ item.item_id }}
            </div>
          </template>
        </template>

        <v-card >

          <v-card-title
            class="headline grey lighten-2"
            primary-title
          >
            {{ item.item_id }}
          </v-card-title>

          <v-card-text class="pt-3">

            <!-- dance start -->
            <template v-if="item.type === 500">

                <v-row>
                  <v-col cols="12" md="7">
                    <div :id="youtubeWrapId">
                      <youtube  :video-id="item.media_video_youtube_code" width="100%" ref="youtube" ></youtube>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5">

                    <div class="title mb-2 ">{{ ucfirst(item.name) }}</div>
                    {{ item.item_id }}<br/>

                    {{ locationToString(item) }}

                    <div v-if="item.sources.length">
                      <b>{{ $t('source') }}:</b>

                      <span v-for="(source, index) in item.sources" :key="source.id">
                        {{ source.full_name }}<span v-if="index < (item.sources.length - 1)">,</span>
                      </span>
                    </div>

                    <div v-if="item.date_of_collecting">
                      <b>{{ $t('date_of_collecting') }}</b>: {{ item.date_of_collecting }}
                    </div>
                    <div v-else-if="item.date_of_collecting_text">
                      <b>{{ $t('date_of_collecting') }}</b>: {{ item.date_of_collecting_text }}
                    </div>

                    <div v-if="Array.isArray(item.collectors) && item.collectors.length">
                      <b >{{ $t('collector') }}:</b>

                      <span v-for="(collector, index) in item.collectors" :key="collector.id">
                        {{ collector.full_name }}<span v-if="index < (item.collectors.length - 1)">; </span>
                      </span>
                    </div>

                    <div v-if="typeof (item.taxonomy_1) !== 'undefined'">
                      <b >{{ $t('dance_dialect') }}:</b>
                      {{ taxonomiesToString(item.taxonomy_1) }}
                    </div>

                    <div v-if="typeof (item.taxonomy_2) !== 'undefined'">
                      <b >{{ $t('dance_type') }}:</b>
                      {{ taxonomiesToString(item.taxonomy_2) }}
                    </div>

                    <div v-if="my_films.length">
                      <b >{{ $t('film') }}:</b>&nbsp;

                      <span v-for="(film, index) in my_films" :key="film.id">
                        <a :href="itemLink('film', film.id)" target="_blank">{{ film.item_id }}</a><span v-if="index < (my_films.length - 1)">; </span>
                      </span>
                    </div>

                    <div v-if="my_notations.length">
                      <b >{{ $t('dance_notation') }}:</b>&nbsp;

                      <span v-for="(notation, index) in my_notations" :key="notation.id">
                        <a :href="itemLink('notation', notation.id)" target="_blank">{{ notation.item_id }}</a><span v-if="index < (my_notations.length - 1)">; </span>
                      </span>
                    </div>

                    <div v-if="item.collector_comment">
                      <b>{{ $t('comment') }}</b>: {{ item.collector_comment }}
                    </div>

                  </v-col>
                </v-row>

            </template>
            <!-- dance end -->

            <!-- photo start -->
            <template v-if="item.type === 300">

                <v-row>
                  <v-col cols="12" md="7">
                    <v-img
                      :src="imgResized(item.media_image_file, {'width': 800, 'height': 800})"
                      v-if="item.media_image_file"
                    ></v-img>
                    <v-img
                      :src="photocard_missing"
                      v-else
                    ></v-img>
                  </v-col>
                  <v-col cols="12" md="5">

                    <div class="title mb-2 ">{{ ucfirst(item.name) }}</div>
                    {{ item.item_id }}<br/>

                    {{ locationToString(item) }}

                    <div v-if="Array.isArray(item.informers) && item.informers.length">
                      <b>{{ $t('informers') }}:</b>

                      <span v-for="(informer, index) in item.informers" :key="informer.id">
                        {{ informer.full_name }}<span v-if="index < (item.informers.length - 1)">,</span>
                      </span>
                    </div>

                    <div v-if="item.date_of_collecting">
                      <b>{{ $t('date_of_collecting') }}</b>: {{ item.date_of_collecting }}
                    </div>
                    <div v-else-if="item.date_of_collecting_text">
                      <b>{{ $t('date_of_collecting') }}</b>: {{ item.date_of_collecting_text }}
                    </div>

                    <div v-if="Array.isArray(item.collectors) && item.collectors.length">
                      <b >{{ $t('collector') }}:</b>

                      <span v-for="(collector, index) in item.collectors" :key="collector.id">
                        {{ collector.full_name }}<span v-if="index < (item.collectors.length - 1)">; </span>
                      </span>
                    </div>

                    <div v-if="item.photo_size">
                      <b>{{ $t('photo_size') }}</b>: {{ item.photo_size }}
                    </div>

                    <div v-if="item.collector_comment">
                      <b>{{ $t('comment') }}</b>: {{ item.collector_comment }}
                    </div>

                    <div v-if="photo_color">
                      <b>{{ $t('photo_color') }}</b>: {{ photo_color }}
                    </div>

                  </v-col>
                </v-row>

            </template>
            <!-- photo end -->

            <!-- dance notation start -->
            <template v-if="item.type === 600">

              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-if="item.name">
                      <td><b>{{ $t('dance_name') }}</b></td>
                      <td>{{ ucfirst(item.name) }}</td>
                    </tr>
                    <tr v-if="locationToString(item)">
                      <td><b>{{ $t('location') }}</b></td>
                      <td>{{ locationToString(item) }}</td>
                    </tr>
                    <tr v-if="my_dances.length">
                      <td><b>{{ $t('dance') }}</b></td>
                      <td>
                        <span v-for="(dance, index) in my_dances" :key="dance.id">
                          <a :href="itemLink('dance', dance.id)" target="_blank">{{ dance.item_id }}</a><span v-if="index < (my_dances.length - 1)">; </span>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="notation_my_sources.length">
                      <td><b>{{ $t('notation_source') }}</b></td>
                      <td>
                        <span v-for="(film, index) in notation_my_sources" :key="film.id">
                          <a :href="itemLink('film', film.id)" target="_blank">{{ film.item_id }}</a><span v-if="index < (notation_my_sources.length - 1)">; </span>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="item.sources.length">
                      <td><b>{{ $t('sources') }}</b></td>
                      <td>
                        <span v-for="(source, index) in item.sources" :key="source.id">
                          {{ source.full_name }}<span v-if="index < (item.sources.length - 1)">; </span>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="Array.isArray(item.notators) && item.notators.length">
                      <td><b>{{ $t('notation_descriptor') }}</b></td>
                      <td>
                        <span v-for="(notator, index) in item.notators" :key="notator.id">
                          {{ notator.full_name }}<span v-if="index < (item.notators.length - 1)">; </span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><b>{{ $t('notation_date_of_collecting') }}</b></td>
                      <td>{{ item.date_of_collecting_text }}</td>
                    </tr>
                    <tr v-if="item.dance_measures">
                      <td><b>{{ $t('dance_measures') }}</b></td>
                      <td>{{ item.dance_measures }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_1) !== 'undefined'">
                      <td><b>{{ $t('dance_dialect') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_1) }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_2) !== 'undefined'">
                      <td><b>{{ $t('dance_type') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_2) }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_3) !== 'undefined'">
                      <td><b>{{ $t('dance_genre') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_3) }}</td>
                    </tr>
                    <tr v-if="item.collector_comment">
                      <td><b>{{ $t('comment') }}</b></td>
                      <td><span v-html="item.collector_comment"></span></td>
                    </tr>
                    <!-- fixme: handle the woa links -->
                    <tr v-if="item.published_txt">
                      <td><b>{{ $t('published_in') }}</b></td>
                      <td>
                        <component v-bind:is="processedPublishedTxt"></component>                      
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </template>
            <!-- dance notation end -->

            <!-- film start -->
            <template v-if="item.type === 200">

              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-if="item.name">
                      <td><b>{{ $t('content') }}</b></td>
                      <td>{{ ucfirst(item.name) }}</td>
                    </tr>
                    <tr v-if="locationToString(item)">
                      <td><b>{{ $t('location') }}</b></td>
                      <td>{{ locationToString(item) }}</td>
                    </tr>
                    <tr v-if="locationToString(item, {location: item.location_5, location_now: '', county: item.location_6})">
                      <td><b>{{ $t('location_of_research') }}</b></td>
                      <td>{{ locationToString(item, {location: item.location_5, location_now: '', county: item.location_6}) }}</td>
                    </tr>
                    <tr v-if="item.date_of_collecting_text ">
                      <td><b>{{ $t('date_of_collecting') }}</b></td>
                      <td>{{ item.date_of_collecting_text  }}</td>
                    </tr>
                    <tr v-if="Array.isArray(item.collectors) && item.collectors.length">
                      <td><b>{{ $t('collector') }}</b></td>
                      <td>
                        <span v-for="(collector, index) in item.collectors" :key="collector.id">
                          {{ collector.full_name }}<span v-if="index < (item.collectors.length - 1)">; </span>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_15) !== 'undefined'">
                      <td><b>{{ $t('nationality') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_15) }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_4) !== 'undefined'">
                      <td><b>{{ $t('film_genre') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_4) }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_5) !== 'undefined'">
                      <td><b>{{ $t('film_ocassion') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_5) }}</td>
                    </tr>
                    <tr v-if="item.collector_comment">
                      <td><b>{{ $t('comment') }}</b></td>
                      <td><span v-html="item.collector_comment"></span></td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_6) !== 'undefined'">
                      <td><b>{{ $t('film_type') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_6) }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_7) !== 'undefined'">
                      <td><b>{{ $t('film_size') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_7) }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_8) !== 'undefined'">
                      <td><b>{{ $t('film_color') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_8) }}</td>
                    </tr>
                    <tr v-if="item.film_length ">
                      <td><b>{{ $t('film_length') }}</b></td>
                      <td>{{ item.film_length }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_9) !== 'undefined'">
                      <td><b>{{ $t('film_audio') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_9) }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_10) !== 'undefined'">
                      <td><b>{{ $t('film_fps') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_10) }} {{ $t('film_fps_suffix') }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </template>
            <!-- film end -->

            <!-- script start -->
            <template v-if="item.type === 800">

              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-if="item.name">
                      <td><b>{{ $t('title') }}</b></td>
                      <td>{{ ucfirst(item.name) }}</td>
                    </tr>
                    <tr v-if="locationToString(item)">
                      <td><b>{{ $t('location') }}</b></td>
                      <td>{{ locationToString(item) }}</td>
                    </tr>
                    <tr v-if="item.date_of_collecting_text ">
                      <td><b>{{ $t('date_of_collecting') }}</b></td>
                      <td>{{ item.date_of_collecting_text  }}</td>
                    </tr>
                    <tr v-if="Array.isArray(item.collectors) && item.collectors.length">
                      <td><b>{{ $t('collector') }}</b></td>
                      <td>
                        <span v-for="(collector, index) in item.collectors" :key="collector.id">
                          {{ collector.full_name }}<span v-if="index < (item.collectors.length - 1)">; </span>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="Array.isArray(item.authors) && item.authors.length">
                      <td><b>{{ $t('script_author') }}</b></td>
                      <td>
                        <span v-for="(author, index) in item.authors" :key="author.id">
                          {{ author.full_name }}<span v-if="index < (item.authors.length - 1)">; </span>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="Array.isArray(item.informers) && item.informers.length">
                      <td><b>{{ $t('source') }}</b></td>
                      <td>
                        <span v-for="(source, index) in item.informers" :key="source.id">
                          {{ source.full_name }}<span v-if="index < (item.informers.length - 1)">; </span>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_12) !== 'undefined'">
                      <td><b>{{ $t('script_genre') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_12) }}</td>
                    </tr>
                    <tr v-if="item.date_of_creating_text">
                      <td><b>{{ $t('date_of_creating') }}</b></td>
                      <td>{{ item.date_of_creating_text }}</td>
                    </tr>
                    <tr v-if="item.extent">
                      <td><b>{{ $t('extent') }}</b></td>
                      <td>{{ item.extent }}</td>
                    </tr>
                    <tr v-if="item.other_comment">
                      <td><b>{{ $t('comment') }}</b></td>
                      <td><span v-html="item.other_comment"></span></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </template>
            <!-- script end -->

            <!-- rhyme start -->
            <template v-if="item.type === 1100">
      
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-if="item.name">
                      <td><b>{{ $t('rhyme_name') }}</b></td>
                      <td>{{ ucfirst(item.name) }}</td>
                    </tr>
                    <tr v-if="locationToString(item)">
                      <td><b>{{ $t('location') }}</b></td>
                      <td>{{ locationToString(item) }}</td>
                    </tr>
                    <tr v-if="item.lyrics">
                      <td><b>{{ $t('rhyme_lyrics') }}</b></td>
                      <td><div class="lyrics" v-html="item.lyrics"></div></td>
                    </tr>
                    <tr v-if="item.collector_comment">
                      <td><b>{{ $t('comment') }}</b></td>
                      <td><span v-html="item.collector_comment"></span></td>
                    </tr>
                    <tr v-if="rhyme_published_in.length">
                      <td><b>{{ $t('published_in') }}</b></td>
                      <td>
                        <span v-for="(biblio, index) in rhyme_published_in" :key="biblio.id">
                          <a :href="itemLink('bibliography', biblio.id)" target="_blank">{{ biblio.name }}</a><span v-if="index < (rhyme_published_in.length - 1)">; </span>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </template>
            <!-- rhyme end -->

            <!-- motif -->
            <template v-if="item.type === 900">

                <v-row>
                  <v-col cols="12" md="7">
                    [ kép ]
                  </v-col>
                  <v-col cols="12" md="5">

                    <div class="title mb-2 ">{{ ucfirst(item.name) }}</div>
                    {{ item.item_id }}<br/>

                    {{ locationToString(item) }}

                    <div v-if="item.collectors.length">
                      <b >{{ $t('collector') }}:</b>

                      <span v-for="(collector, index) in item.collectors" :key="collector.id">
                        {{ collector.name }}<span v-if="index < (item.collectors.length - 1)">; </span>
                      </span>
                    </div>

                    <div v-if="typeof (item.taxonomy_1) !== 'undefined'">
                      <b >{{ $t('dance_dialect') }}:</b>
                      {{ taxonomiesToString(item.taxonomy_1) }}
                    </div>

                    <div v-if="typeof (item.taxonomy_2) !== 'undefined'">
                      <b >{{ $t('dance_type') }}:</b>
                      {{ taxonomiesToString(item.taxonomy_2) }}
                    </div>

                  </v-col>
                </v-row>

            </template>
            <!-- motif end -->

            <!-- historical source start -->
            <template v-if="item.type === 1200">
      
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-if="item.name">
                      <td><b>{{ $t('historicalsource_name') }}</b></td>
                      <td>{{ ucfirst(item.name) }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_16) !== 'undefined'">
                      <td><b>{{ $t('dance_name') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_16) }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_17) !== 'undefined'">
                      <td><b>{{ $t('historicalsource_keyword') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_17) }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_18) !== 'undefined'">
                      <td><b>{{ $t('ethnicity') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_18) }}</td>
                    </tr>
                    <tr v-if="locationToString(item, {location: item.location_8, location_now: '', county: item.location_7})">
                      <td><b>{{ $t('locality') }}</b></td>
                      <td>{{ locationToString(item, {location: item.location_8, location_now: '', county: item.location_7}) }}</td>
                    </tr>

                    <tr v-if="item.historical_source_text">
                      <td><b>{{ $t('historicalsource_text') }}</b></td>
                      <td>{{ item.historical_source_text }}</td>
                    </tr>
                    <tr v-if="item.collector_comment">
                      <td><b>{{ $t('comment') }}</b></td>
                      <td><span v-html="item.collector_comment"></span></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </template>
            <!-- historical source end -->

            <!-- bibliography start -->
            <template v-if="item.type === 1000">      
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-if="title_in_english_needed">
                      <td><b>{{ $t('title_in_english') }}</b></td>
                      <td>{{ item.title_in_english }}</td>
                    </tr>
                    <tr v-if="item.date_of_collecting_text">
                      <td><b>{{ $t('biblio_year') }}</b></td>
                      <td>{{ item.date_of_collecting_text }}</td>
                    </tr>
                    <tr v-if="typeof (item.taxonomy_13) !== 'undefined'">
                      <td><b>{{ $t('biblio_genre') }}</b></td>
                      <td>{{ taxonomiesToString(item.taxonomy_13) }}</td>
                    </tr>
                    <tr v-if="item.ext_journal">
                      <td><b>{{ $t('ext_journal') }}</b></td>
                      <td>{{ item.ext_journal }}</td>
                    </tr>
                    <!-- fixme: should check for stri_tags version in every wisiwyg so <p></p> will not couse problems -->
                    <tr v-if="item.editor_comment">
                      <td><b>{{ $t('biblio_editor_comment') }}</b></td>
                      <td> <span v-html="item.editor_comment" /></td>
                    </tr>
                    <tr v-if="Array.isArray(item.external_urls)">
                      <td><b>{{ $t('external_urls') }}</b></td>
                      <td>
                        <span v-for="(url, index) in item.external_urls" :key="url.url">
                          <a :href="url.url" target="_blank" :title="url.url">Link</a><span v-if="index < (item.external_urls.length - 1)">; </span>
                        </span>                        
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </template>
            <!-- bibliography end -->                   

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="primary"
              @click="dialog = false; whenClosed()"
            >
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
</template>

<script>
  import { ES_INDEX } from '@/constants/woa'
  import axios from 'axios'

  export default {
    data () {
      return {
        dialog: false,
        should_cycle: true,
        item: null
      }
    },
    computed: {
      title_in_english_needed () {
        // console.log(this.lang)
        if (this.item.title_in_english && this.lang === 'en' && this.item.name !== this.item.title_in_english) {
          return true
        }
        return false
      },
      rhyme_published_in () {
        var ret = []
        if (this.item.woas.length) {
          for (let woa of this.item.woas) {
            if (woa.type === 1000) {
              // console.log(woa)
              if (this.item.woas.length == 1 && this.item.external_references.length == 1) woa.name = this.item.external_references[0].name
              ret.push(woa)
            }
          }
        }
        return ret
      },
      yt_thumbnail () {
        if (this.item.media_image_file) {
          return this.imgResized(this.item.media_image_file, {'width': 600})
        } else {
          return 'https://img.youtube.com/vi/' + this.item.media_video_youtube_code + '/0.jpg'
        }
      },
      photocard_missing () {
        if (this.$i18n.locale === 'en') {
          return 'https://via.placeholder.com/600x421?text=The+photo+card+is+not+available'
        }
        return 'https://via.placeholder.com/600x421?text=A+fotókarton+nem+érhető+el'
      },
      photo_color () {
        if (this.$i18n.locale === 'en') {
          if (this.item.photo_color === 1) {
            return 'Black & white'
          } else if (this.item.photo_color === 2) {
            return 'Color'
          }
        }
        if (this.item.photo_color === 1) {
          return 'Fekete-fehér'
        } else if (this.item.photo_color === 2) {
          return 'Színes'
        }
        return false;
      },
      my_films () {
        var ret = []
        if (this.item.woas.length) {
          for (let woa of this.item.woas) {
            if (woa.type === 200) {
              // console.log(woa)
              ret.push(woa)
            }
          }
        }
        return ret
      },
      my_notations () {
        var ret = []
        if (this.item.woas.length) {
          for (let woa of this.item.woas) {
            if (woa.type === 600) {
              // console.log(woa)
              ret.push(woa)
            }
          }
        }
        return ret
      },
      my_dances () {
        var ret = []
        if (this.item.woas.length) {
          for (let woa of this.item.woas) {
            if (woa.type === 500) {
              // console.log(woa)
              ret.push(woa)
            }
          }
        }
        return ret
      },
      notation_my_sources () {
        var ret = []
        if (this.item.woas.length) {
          for (let woa of this.item.woas) {
            if (woa.type === 200) {
              // console.log(woa)
              ret.push(woa)
            }
          }
        }
        return ret
      },
      youtubeWrapId () {
        return 'youtube_wrap_' + this.item.id
      },
      processedPublishedTxt () {
        var htmlArr0 = this.item.published_txt.split('<span data-type="woaLink" data-id="')
        var i, html, htmlArr1, htmlArr2, id, text, div, actPart
        html = htmlArr0[0]
        for (i = 1; i < htmlArr0.length; i++) {
          htmlArr1 = htmlArr0[i].split('</span>')
          // if (parseInt(this.item.id) === 85) console.log('htmlArr1', htmlArr1)
          htmlArr2 = htmlArr1[0].split('">')
          // if (parseInt(this.item.id) === 85) console.log('htmlArr2', htmlArr2)
          id = htmlArr2[0]
          // str_tags() in JS
          div = document.createElement('div')
          div.innerHTML = htmlArr2[1]
          text = div.innerText
          if (id.length > 0) {
            htmlArr1.shift()
            // actPart = '<woa-dialog id="' + id + '" lang="' + this.lang + '" inlineTextLink=1 text="' + text + '"></woa-dialog>' + htmlArr1.join('</span>')
            actPart = '<a :href="itemLink(\'bibliography\', \'' + id + '\')" target="_blank">' + text + '</a>' + htmlArr1.join('</span>')
            // if (parseInt(this.item.id) === 85) console.log(actPart)
            html += actPart
          } else {
            html += htmlArr0[i]
          }
        }
        return {
          template: '<span>' + html + '</span>'
        }
      }

    },
    methods: {
      whenClosed () {
        // stop yputube playback when dialog is closed
        var el = document.querySelector('div#youtube_wrap_' + this.item.id + ' iframe')
        if (typeof el !== 'undefined' && el !== null && !this.dialog) {
          el.src = el.src // eslint-disable-line no-self-assign
        }
      }
    },
    mounted () {
      if (this.itemObject) {
        this.item = this.itemObject
      } else if (this.id && this.type) {
        // console.log('fetching from ES')
        /*
        const query = {
          query: {
            match: {
              'id': this.id
            }
          }
        }
        */

        let query = {
          query: {
            match: {
              id: this.id,
            },
          },
        };

        if (isNaN(this.id)) {
          query = {
            query: {
              match: {
                item_id: this.id,
              },
            },
          };
        }
        // console.log(query)

        if (typeof (ES_INDEX[this.type][this.$i18n.locale]) !== 'undefined') {
          const esIndex = ES_INDEX[this.type][this.$i18n.locale]
          axios.get(process.env.VUE_APP_ELASTIC_ENDPOINT + '/' + esIndex + '/_search', {
            params: {
              source: JSON.stringify(query),
              source_content_type: 'application/json'
            },
            auth: {
              username: process.env.VUE_APP_ES_AUTH_USERNAME,
              password: process.env.VUE_APP_ES_AUTH_PASSWORD
            }
          }).then((res) => {
            if (res.status === 200) {
              if (typeof (res.data.hits.total) !== 'undefined' && res.data.hits.total.value === 1) {
                this.item = res.data.hits.hits[0]._source
              }
            }
          })
        }
      }
    },
    name: 'WoaDialog',
    props: ['itemObject', 'lang', 'width', 'id', 'type', 'inlineTextLink', 'text']
  }
</script>

<style>
.yt_thumbnail :hover {
  cursor: pointer;
}
.v-card__title .v-image {
  cursor: pointer;
}
</style>
