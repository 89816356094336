<template>
    <v-dialog
      v-model="dialog"
      max-width="80%"
      scrollable
      v-if="glossary !== null"
      style='z-index:5001;'
    >
      <template v-slot:activator="{ on }">
        <span
          class="text-in-inline primary--text"
          v-if="inline === '1'"
          v-on="on"
        >{{ text }}</span>
        <v-btn
          color="primary"
          small
          v-on="on"
          class="ma-1"
          v-else
        >{{ glossary.keyword }}</v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{ glossary.keyword }}
        </v-card-title>

        <v-card-text v-html="glossary.description" class="pt-3">
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn>
            <!--
            <router-link :to="$i18nRoute({ name: 'keyword', params: { id: glossary.id } })">
              {{ $t('details') }}
            </router-link>
            -->
            <a :href="detailsLink">{{ $t('details') }}</a>
          </v-btn>
          <v-btn
            color="primary"
            @click="dialog = false"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  import { ES_INDEX } from '@/constants/woa'
  import axios from 'axios'

  export default {
    data () {
      return {
        dialog: false,
        glossary: null
      }
    },
    computed: {
      detailsLink () {
        return '/' + this.$i18n.locale + '/keyword/' + this.id
      }
    },
    name: 'GlossaryDialog',
    props: ['id', 'lang', 'inline', 'text'],
    mounted () {
      const query = {
        query: {
          match: {
            'id': this.id
          }
        }
      }
      if (typeof (ES_INDEX['lexicon'][this.$i18n.locale]) !== 'undefined') {
        const esIndex = ES_INDEX['lexicon'][this.$i18n.locale]
        axios.get(process.env.VUE_APP_ELASTIC_ENDPOINT + '/' + esIndex + '/_search', {
          params: {
            source: JSON.stringify(query),
            source_content_type: 'application/json'
          },
          auth: {
            username: process.env.VUE_APP_ES_AUTH_USERNAME,
            password: process.env.VUE_APP_ES_AUTH_PASSWORD
          }
        }).then((res) => {
          if (res.status === 200) {
            if (typeof (res.data) !== 'undefined' && typeof (res.data.hits) !== 'undefined' && res.data.hits.total.value === 1) {
              this.glossary = res.data.hits.hits[0]._source
            }
          }
        })
      }
    }
  }
</script>

<style scoped>
.text-in-inline {
  text-decoration: underline;
  cursor: pointer;
}
.v-card__actions .v-btn__content a {
  text-decoration: none;
}
</style>
