import gql from 'graphql-tag'

export const PAGE_QUERY = gql`
  query pageQuery($lang: String!, $slug: String) {
    page (lang: $lang, slug: $slug) {
      id
      name
      content_blocks
    }
  }
`
