<template>
  <v-app>
    <div v-if="isLoading"></div>
    <div v-else>
      <Nav />

      <v-main>
        <router-view class="ma-5"/>
      </v-main>

      <Footer />
    </div>
  </v-app>
</template>

<script>
import Nav from "@/components/Nav"
import Footer from "@/components/Footer"
import EventBus from "@/EventBus"

import '@/sass/app.scss'

export default {
  data: () => ({
    isLoading: true
  }),
  mounted() {
    EventBus.$on("i18n-load-start", () => (this.isLoading = true))

    EventBus.$on("i18n-load-complete", () => (this.isLoading = false))
  },
  components: { Nav, Footer }
}
</script>

<style>
</style>
