<!--
 TODO

 - make the links inside v-tab fill the tab completely
 - rework LocaleSwitcher

-->

<template>
  <div>
    <v-app-bar
      app
      :src="headerImage"
      v-if="isMobile"
      class="mobileHeader"
    >
          <v-app-bar-nav-icon @click="drawer = !drawer" class="d-flex d-md-none"></v-app-bar-nav-icon>

          <v-toolbar-title style="padding-left: 0"><a href="/" style="text-decoration: none;" class="appname ml-3 mr-5">
            <v-img contain v-on:click.native="jumpToLang('/')" src="/img/logo.png" max-height="40" class="ml-auto"></v-img>
          </a></v-toolbar-title>

          <div class="flex-grow-1"></div>

          <div>
            <v-img contain v-on:click.native="jumpToLang('/en')" src="/img/en_flag.png" class="mb-2" max-height="20" max-width="50"></v-img>
            <v-img contain v-on:click.native="jumpToLang('/hu')" src="/img/hu_flag.png"  max-height="20" max-width="50"></v-img>
          </div>

      <!--
      <LocaleSwitcher />
      -->
    </v-app-bar>

    <v-app-bar
      prominent
      app
      :src="headerImage"
      v-else
    >

      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          position="left center"
        ></v-img>
      </template>

      <v-app-bar-nav-icon @click="drawer = !drawer" class="d-flex d-md-none"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <a href="/" style="text-decoration: none; " class="appname" >
          <v-img contain v-on:click.native="jumpToLang('/')" :src="logo" style="margin-bottom: 25px;"></v-img>
          <!--
          <img src="/img/logo.png" />

          <div v-html="$t('app.title_html')"></div>
          <div class="subtitle">{{ $t('app.subtitle') }}</div>
          -->
        </a>
      </v-toolbar-title>

      <template v-slot:extension>
        <v-tabs align-with-title class="d-none d-md-flex headerTabs" optional>
          <template v-for="(item, i) in items">
            <v-tab 
              v-if="isExternal(item.url)" 
              :key="i"
            >
              <a :href="item.url[$i18n.locale]" target="_blank">{{ $t(item.text) }}</a>
            </v-tab>

            <v-tab
              v-else
              :key="i"
            >
                <LocalizedLink :to="item.url">{{ $t(item.text) }}</LocalizedLink>
            </v-tab>
          </template>
        </v-tabs>
      </template>

      <div class="flex-grow-1"></div>

      <v-img contain v-on:click.native="jumpToLang('/en')" src="/img/en_flag.png" class="mr-1 mt-2" max-height="30" max-width="80"></v-img>
      <v-img contain v-on:click.native="jumpToLang('/hu')" src="/img/hu_flag.png" class="mr-1 mt-2" max-height="30" max-width="80"></v-img>
      <!--
      <LocaleSwitcher />
      -->
    </v-app-bar>


    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
    >
        <div class="align-center">
          <v-list
            dense
          >
            <template v-for="(item, i) in items">
              <v-list-item 
                v-if="isExternal(item.url)" 
                :key="i"
              >
                <a :href="item.url[$i18n.locale]" target="_blank">
                <v-list-item-content>
                  <v-list-item-title class="text-white">
                    {{ $t(item.text) }}
                  </v-list-item-title>
                </v-list-item-content>
                </a>
              </v-list-item>

              <v-list-item
                v-else
                :key="i"
              >
                  <LocalizedLink :to="item.url">
                    <v-list-item-content>
                      <v-list-item-title class="text-white">
                        {{ $t(item.text) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </LocalizedLink>
              </v-list-item>
            </template>

          </v-list>
        </div>

    </v-navigation-drawer>

  </div>
</template>

<script>
// import LocaleSwitcher from "@/components/LocaleSwitcher"
import LocalizedLink from "@/components/LocalizedLink"

export default {
  data: () => ({
    drawer: false,
    items: [
      { text: 'nav.films', url: 'films' },
      { text: 'nav.dances', url: 'dances' },
      { text: 'nav.photos', url: 'photos' },
      { text: 'nav.manuscripts', url: 'scripts' },
      { text: 'nav.dancenotations', url: 'dancenotations' },
      { text: 'nav.motifs', url: 'motifs' },
      { text: 'nav.dancerhymes', url: 'rhymes' },
      { text: 'nav.historical_sources', url: 'historicalsources' },
      { text: 'nav.lexicon', url: 'lexicon' },
      { text: 'nav.bibliography', url: 'bibliography' },
      { text: 'nav.ebooks', url: 'ebooks' },
      { text: 'nav.labangraph', url: 'labangraph' },
      /*
      { text: 'nav.ebooks', url: { hu: 'http://db.zti.hu/neptanc_tudastar/ekonyvek.asp', en: 'http://db.zti.hu/neptanc_tudastar/ekonyvek.asp' } },
      { text: 'nav.labangraph', url: { hu: 'http://db.zti.hu/neptanc_tudastar/labangraph.asp', en: 'http://db.zti.hu/neptanc_tudastar/labangraph_en.asp' } }
      { text: 'nav.about', url: 'page', params: {hu: {slug: 'ismerteto'}, en: {slug: 'about'}} }
      */
    ]
  }),
  methods: {
    jumpToLang (langcode) {
      window.location.href = langcode
    },
    isExternal (url) {
      // return url.substring(0, 4) === 'http'
      return typeof url.hu !== 'undefined'
    }
  },
  computed: {
    isMobile () {
      var ret = false
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          ret = true; break
        case 'sm':
          ret = true; break
      }
      return ret
    },
    logo() {
      if (this.$i18n.locale === 'en') {
        return "/img/logo_en.png";
      } else {
        return "/img/logo.png";
      }
    },
    headerImage () {
      if (this.$route.name === 'films') return "/img/header/" + this.$i18n.locale + "/fejlec_filmek.png"
      if (this.$route.name === 'dances') return "/img/header/" + this.$i18n.locale + "/fejlec_tancok.png"
      if (this.$route.name === 'photos') return "/img/header/" + this.$i18n.locale + "/fejlec_fotok.png"
      if (this.$route.name === 'scripts') return "/img/header/" + this.$i18n.locale + "/fejlec_keziratok.png"
      if (this.$route.name === 'dancenotations') return "/img/header/" + this.$i18n.locale + "/fejlec_tancirasok.png"
      if (this.$route.name === 'motifs') return "/img/header/" + this.$i18n.locale + "/fejlec_motivumok.png"
      if (this.$route.name === 'rhymes') return "/img/header/" + this.$i18n.locale + "/fejlec_tancszok.png"
      if (this.$route.name === 'historicalsources') return "/img/header/" + this.$i18n.locale + "/fejlec_tortenetiforrasok.png"
      if (this.$route.name === 'lexicon') return "/img/header/" + this.$i18n.locale + "/fejlec_lexikon.png"
      if (this.$route.name === 'bibliography') return "/img/header/" + this.$i18n.locale + "/fejlec_bibliografia.png"
      if (this.$route.name === 'ebooks') return "/img/header/" + this.$i18n.locale + "/fejlec_ekonyvek.png"
      if (this.$route.name === 'labangraph') return "/img/header/" + this.$i18n.locale + "/fejlec_labangraph.png"

      return "/img/header/" + this.$i18n.locale + "/fejlec_nyitooldal.png"
    }
  },
  components: { 
    // LocaleSwitcher, 
    LocalizedLink 
  }
}
</script>

<style scoped>
.headerTabs {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkmAkAAJ8AmzsOXcYAAAAASUVORK5CYII=);
}
.v-tab a {
  color: white;
  text-decoration: none;
  height: 100%;
  padding-top: 14px;
}
.v-app-bar.v-app-bar--fixed {
  z-index: 1000;
}
.v-navigation-drawer {
  z-index: 1001;
}
#app .v-toolbar__image .v-image__image--cover {
  background-position: left center !important;
}
</style>
