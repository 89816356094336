<template>
  <div>
    <v-container>
      <page :page="page" :lang="this.$i18n.locale" v-if="page"> </page>

      <!-- pdf links start -->
      <v-row align="center" class="pdfLinks">
        <v-col class="text-center" cols="12" sm="6" lg="3">
          <div class="m-2">
            <a :href="$t('home.pdf_1.link')" target="_blank">
              <v-card class="secondary lighten-1">
                <v-card-text>{{ $t("home.pdf_1.name") }}</v-card-text>
              </v-card>
            </a>
          </div>
        </v-col>
        <v-col class="text-center" cols="12" sm="6" lg="3">
          <div class="m-2">
            <a :href="$t('home.pdf_2.link')" target="_blank">
              <v-card class="secondary lighten-1">
                <v-card-text>{{ $t("home.pdf_2.name") }}</v-card-text>
              </v-card>
            </a>
          </div>
        </v-col>
        <v-col class="text-center" cols="12" sm="6" lg="3">
          <div class="m-2">
            <a :href="$t('home.pdf_3.link')" target="_blank">
              <v-card class="secondary lighten-1">
                <v-card-text>{{ $t("home.pdf_3.name") }}</v-card-text>
              </v-card>
            </a>
          </div>
        </v-col>
        <v-col class="text-center" cols="12" sm="6" lg="3">
          <div class="m-2">
            <a :href="$t('home.pdf_4.link')" target="_blank">
              <v-card class="secondary lighten-1">
                <v-card-text>{{ $t("home.pdf_4.name") }}</v-card-text>
              </v-card>
            </a>
          </div>
        </v-col>
      </v-row>
      <!-- pdf links  end-->

      <!-- databases start -->
      <br />
      <div class="display-1 text-center my-5">{{ $t("home.databases") }}</div>
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex d-flex xs12 sm6 md4 xl3 pa-3 v-for="n in 12" :key="n">
            <v-card>
              <LocalizedLink :to="$t(db_page(n))" v-if="$t(db_page(n))"
                ><v-img height="200" :src="$t(db_image(n))"></v-img
              ></LocalizedLink>
              <a v-else :href="$t(db_external_url(n))" target="_blank"
                ><v-img height="200" :src="$t(db_image(n))"></v-img
              ></a>
              <v-card-title
                ><LocalizedLink :to="$t(db_page(n))">{{
                  $t(db_name(n))
                }}</LocalizedLink></v-card-title
              >
              <v-card-text>{{ $t(db_text(n)) }}</v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <!-- databases end -->
    </v-container>
  </div>
</template>

<script>
import Page from "@/components/Page";
import { PAGE_QUERY } from "@/constants/graphql";
import LocalizedLink from "@/components/LocalizedLink";

export default {
  name: "Home",
  data() {
    return {
      page: null,
      lang: this.$i18n.locale,
      slug: "home",
    };
  },
  components: {
    Page,
    LocalizedLink,
  },
  methods: {
    db_name(n) {
      return "home.db_" + n + ".name";
    },
    db_image(n) {
      return "home.db_" + n + ".image";
    },
    db_page(n) {
      return "home.db_" + n + ".page";
    },
    db_external_url(n) {
      return "home.db_" + n + ".external_url";
    },
    db_text(n) {
      return "home.db_" + n + ".text";
    },
  },
  apollo: {
    page: {
      query: PAGE_QUERY,
      variables() {
        return {
          slug: "home",
          lang: this.$i18n.locale,
        };
      },
    },
  },
};
</script>

<style>
.pdfLinks .v-card__text {
  color: white !important;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdfLinks a {
  text-decoration: none;
}
</style>
