import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    woa_list_view: false
  },
  mutations: {
    setWoaListView (state, value) {
      state.woa_list_view = value
    }
  },
  actions: {},
  modules: {}
});
