import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import Root from "./Root"
import i18n, { loadLocaleMessagesAsync } from "@/i18n"
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle
} from "@/util/i18n/document"

Vue.use(VueRouter)

const { locale } = i18n

const routes = [
  {
    path: "/",
    redirect: locale
  },
  {
    path: "/:locale",
    component: Root,
    children: [
      {
        path: "",
        name: "home",
        component: Home
      },
      {
        path: "labangraph",
        name: "labangraph",
        component: () =>
          import(/* webpackChunkName: "labangraph" */ "../views/Labangraph.vue")
      },
      {
        path: "page/:slug",
        name: "page",
        component: () =>
          import(/* webpackChunkName: "page" */ "../views/Page.vue")
      },
      {
        path: 'keyword/:id',
        name: 'keyword',
        component: () =>
          import(/* webpackChunkName: "keyword" */ "../views/Keyword.vue")
      },
      {
        path: "films",
        name: "films",
        component: () =>
          import(/* webpackChunkName: "films" */ "../views/Films.vue")
      },
      {
        path: "dances",
        name: "dances",
        component: () =>
          import(/* webpackChunkName: "dances" */ "../views/Dances.vue")
      },
      {
        path: "photos",
        name: "photos",
        component: () =>
          import(/* webpackChunkName: "photos" */ "../views/Photos.vue")
      },
      {
        path: "scripts",
        name: "scripts",
        component: () =>
          import(/* webpackChunkName: "scripts" */ "../views/Scripts.vue")
      },
      {
        path: "rhymes",
        name: "rhymes",
        component: () =>
          import(/* webpackChunkName: "rhymes" */ "../views/Rhymes.vue")
      },
      {
        path: "historicalsources",
        name: "historicalsources",
        component: () =>
          import(/* webpackChunkName: "historicalsources" */ "../views/Historicalsources.vue")
      },
      {
        path: "motifs",
        name: "motifs",
        component: () =>
          import(/* webpackChunkName: "motifs" */ "../views/Motifs.vue")
      },
      {
        path: 'item/:type/:id',
        name: 'item',
        component: () =>
          import(/* webpackChunkName: "item" */ "../views/Item.vue")
      },
      {
        path: 'bibliography',
        name: 'bibliography',
        component: () =>
          import(/* webpackChunkName: "bibliography" */ "../views/Bibliography.vue")
      },
      {
        path: 'ebooks',
        name: 'ebooks',
        component: () =>
          import(/* webpackChunkName: "ebook" */ "../views/Ebooks.vue")
      },
      {
        path: "lexicon",
        name: "lexicon",
        component: () =>
          import(/* webpackChunkName: "lexicon" */ "../views/Lexicon.vue")
      },
      {
        path: "dancenotations",
        name: "dancenotations",
        component: () =>
          import(/* webpackChunkName: "dancenotations" */ "../views/Dancenotations.vue")
      }
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.params.locale === from.params.locale) {
    next()
    return
  }

  const { locale } = to.params

  loadLocaleMessagesAsync(locale).then(() => {
    setDocumentLang(locale)

    setDocumentDirectionPerLocale(locale)

    setDocumentTitle(i18n.t("app.title"))
  })

  next()
})

export default router
